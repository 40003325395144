<template>
  <div class="backMenu">
    <div class="">
      <el-card class="box-card">
        <div>
          <span>当前会员：</span><span>{{ this.$route.query.name }}</span>
        </div>
        <div class="condition">
          <div class="left">
            <div class="search-box">
              <div class="sch-1 sch-2">
                <div class="sch-title">时间:</div>
                <el-date-picker
                  v-model="MenuApi.createTime"
                  type="datetimerange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
              <div class="sch-1 sch-2 sch-3">
                <el-button type="primary" size="mini" @click="toSearch"
                  >搜索</el-button
                >
                <span class="clear-elbtn">
                  <el-button icon="el-icon-s-release" @click="celar"
                    >清除条件</el-button
                  >
                </span>
              </div>
            </div>
          </div>
          <div class="right">
            <div>
              <span>当前账户余额：</span>
              <span>{{ rolaList.money }}</span>
            </div>
            <div
              class="btn"
              @click="toAdjust"
              v-if="BtnJurisdiction(`/User/accountHandle`)"
            >
              调节账户余额
            </div>
          </div>
        </div>
        <!-- 角色列表 -->
        <div class="MenuList scorr-roll">
          <el-table :data="rolaList.data.data" border style="width: 100%">
            <el-table-column
              prop="create_time"
              label="时间"
              min-width="100"
              align="center"
            >
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.create_time }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="变动原因"
              min-width="150"
              align="center"
            >
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.remarks }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="变动类型"
              min-width="150"
              align="center"
            >
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.ident == 1 ? "增加" : "减少" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="变动金额"
              width="200"
              align="center"
            >
              <template slot-scope="{ row }">
                <div>{{ row.change }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="paging" v-if="rolaList.data">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="15"
            layout="total,prev, pager, next, jumper"
            :total="rolaList.data.total"
          >
          </el-pagination>
        </div>
        <!-- 调节弹框 -->
        <div>
          <el-dialog title="提示" :visible.sync="isAdjust" width="700px">
            <div>
              <el-form label-width="100px">
                <el-form-item label="当前会员：" required>
                  <div>{{ this.$route.query.name }}</div>
                </el-form-item>
                <el-form-item label="变动原因：" required>
                  <el-input
                    v-model="form.remarks"
                    placeholder="请编辑变动原因"
                  ></el-input>
                </el-form-item>
                <el-form-item label="变动方式:">
                  <el-radio v-model="form.ident" label="1">增加</el-radio>
                  <el-radio v-model="form.ident" label="2">减少</el-radio>
                </el-form-item>
                <el-form-item label="变动金额：" v-if="form.ident" required>
                  <div class="YuE">
                    <div class="">
                      <el-input
                        v-model="form.change"
                        @blur="blurChange(form.change)"
                        placeholder="请输入变动金额"
                      ></el-input>
                    </div>
                    <div class="WZ">当前余额：{{ rolaList.money }}</div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="isAdjust = false">取 消</el-button>
              <el-button type="primary" @click="affirm">确 定</el-button>
            </span>
          </el-dialog>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import BtnJurisdiction from "@/utils/jurisdictionbtn"; //按钮权限公共方法
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("user"); //vuex公共库
export default {
  data() {
    return {
      isEdit: false,
      rolaList: {
          data:{
              data:[    ]
          }
      },
      MenuApi: {
        id: this.$route.query.id,
        createTime: "",
        page: 1,
      },
      isAddBackPerson: false, //弹框
      addData: {
        name: "", //名称
        ids: [], //权限集合
        roleId: "", //角色id 修改必须
      },
      PowerDataList: [], //储存选中权限
      allPowerList: [], //所有权限
      searchData: {},
      isAdjust: false,
      form: {
        userId: this.$route.query.id,
      },
    };
  },
  created() {
    this.getAccountLost();
  },
  methods: {
    // 按钮权限
    BtnJurisdiction,
    ...mapActions(["postaccountList", "postaccountHandle"]),
    // 去调节
    toAdjust() {
      this.form = {
        userId: this.$route.query.id,
      };
      this.isAdjust = true;
    },
    // 确认变动
    async affirm() {
      if (
        !this.form.ident ||
        !this.form.change ||
        !this.form.remarks ||
        !Number(this.form.change)
      ) {
        this.$message({
          message: "请确认数据是否正确输入",
          type: "warning",
        });
        return;
      }
      let data = await this.postaccountHandle(this.form);
      if (data.code == 200) {
        this.isAdjust = false;
        this.getAccountLost();
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 获取账户详情
    async getAccountLost() {
   
    },
    // 金额变动
    blurChange(val) {
      if (Number(val) && val > 0) {
        if (this.form.ident == 2) {
          if (Number(this.rolaList.money) >= val && this.form.change >= 0) {
            this.form.change = Number(this.form.change);
          } else {
            this.form.change = 0;
          }
        } else {
          this.form.change = Number(val);
        }
      } else {
        this.$message({
          message: "只能输入大于0的数字",
          type: "warning",
        });
      }
    },
    // 搜索
    toSearch() {
      this.MenuApi.page = 1;
      this.getAccountLost();
    },
    // 翻页 
    handleCurrentChange(e) {
      this.MenuApi.page = e;
      this.getAccountLost();
    },
    celar() {
      (this.MenuApi = {
        id: this.$route.query.id,
        createTime: "",
        page: 1,
      }),
        1;
      this.getAccountLost();
    },
  },
};
</script>
<style lang='scss' scoped>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
.el-checkbox__label {
  color: black;
}
.el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0px;
  margin-top: 5px;
}
.el-tabs__item {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.backMenu {
  width: 100%;
  .MenuList {
    margin-top: 20px;
    width: 100%;
    max-height: 60vh;
    .exceed {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
    .rootBtn {
      button {
        border: none;
        background: none;
        cursor: pointer;
        color: #06B7AE;
      }
    }
  }
  .paging {
    margin-top: 20px;
    display: flex;
    justify-content: right;
  }
  .AddMenu {
    width: 100%;
    max-height: 500px;
    .AddMenu-1-box {
      display: flex;
      justify-content: center;
      .inputbox {
        width: 400px;
      }
    }
  }
  .Edit {
    width: 100%;
    max-height: 650px;
    .inputbox {
      width: 400px;
    }
    .menuPower {
      width: 100%;
      max-height: 325px;
      .menuPower-title {
        font-size: 20px;
        font-weight: bold;
      }
      .menu-top {
        margin-top: 30px;
        .tabs-menu {
          width: 100%;
          height: 260px;
        }
        .ss {
          width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .btnPower {
      margin-top: 20px;
      width: 100%;
      max-height: 300px;
      .btnPower-title {
        font-size: 20px;
        font-weight: bold;
      }
      .menu-top {
        margin-top: 30px;
        .tabs-menu {
          width: 100%;
          max-height: 260px;
        }
        .ss {
          width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .condition {
    margin-top: 20px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      width: 80%;
      height: 40px;
      .search-box {
        min-width: 500px;
        display: flex;
        flex-wrap: wrap;
      }
      .sch-1 {
        width: 300px;
        display: flex;
        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
      .sch-2 {
        margin-right: 2%;
      }
      .sch-3 {
        width: 150px !important;
      }
    }
    .right {
      height: 40px;
      line-height: 40px;
      display: flex;
      div {
        margin-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .btn {
        width: 100px;
        text-align: center;
        color: #fff;
        border-radius: 3px;
        background: #06B7AE;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
  .YuE {
    display: flex;
    .WZ {
      margin-left: 10px;
      font-size: 13px;
    }
  }
  .y-hint {
    font-size: 12px;
  }
}
</style>
